export const ExperimentsConsts = {
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  BookingsFormWidgetCssOptimization:
    'specs.bookings.BookingsFormWidgetCssOptimization',
  EditorOOIFormPage: 'specs.bookings.EditorOOIFormPage',
  MultiServiceAppointment: 'specs.bookings.multiServiceAppointment',
  FormPaymentOptionNewDropdown: 'specs.bookings.FormPaymentOptionNewDropdown',
  BookOnBehalf: 'specs.bookings.BookOnBehalf',
  CancellationFeesUoU: 'specs.bookings.CancellationFeesUoU',
  DetachNumberOfParticipantsFieldFromForm:
    'specs.bookings.DetachNumberOfParticipantsFieldFromForm',
  FormA11y: 'specs.bookings.FormA11y',
  FormUseAutomationsForSMS: 'specs.bookings.FormUseAutomationsForSMS',
  FormGetLocaleFromWixCodeAPI: 'specs.bookings.FormGetLocaleFromWixCodeAPI',
  CheckIsMemberAreaInstalledUsingPublicAPI:
    'specs.bookings.CheckIsMemberAreaInstalledUsingPublicAPI',
  DynamicPriceRefactorForMultilingual:
    'specs.bookings.DynamicPriceRefactorForMultilingual',
  RemoveWixEmbedsAppToken: 'specs.thunderbolt.removeWixEmbedsAppToken',
};
